import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import {
	Button,
	Col,
	FormGroup,
	Label,
	Row,
} from 'reactstrap';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';

import FormTitle from './form-title';
import { MotifFields } from './MotifFields';
import { Rdv } from './optional-fields';

import { FormFaqAssuranceConsulterModifier } from './faq/Assurance-consulter-modifier';
import { FormFaqAssuranceDeclarerSinistre } from './faq/Assurance-declarer-un-sinistre';
import { FormFaqAssuranceAttestation } from './faq/Assurance-attestation';
// import { FormFaqCheckbook } from './faq/Checkbook';
import { FormFaqCode } from './faq/Code';
import { FormFaqFaq } from './faq/Faq';
import { FormFaqOppositionCarte } from './faq/Opposition-carte';
import { FormFaqOppositionPrelevement } from './faq/Opposition-prelevement';
import { FormFaqRIBSmartphone } from './faq/RIB-smartphone';
import { FormFaqVerouillageTemporaire } from './faq/Verrouillage-temporaire-CB';
import { FormFaqVisualiserCodeSecret } from './faq/Visualiser-code-secret';
import { FormFaqOptionInternationale } from './faq/Option-internationale';

import { clientTypes, themes } from '../../schemas/data/data';
import getDemandeSchema from '../../schemas/demande/demande.schema';
import SelectTheme from '../SelectTheme';
import SimpleSelect from '../SimpleSelect';
import { FormFaqModificationAdresse } from './faq/Modification-adresse';
import { FormFaqModificationEmail } from './faq/Modification-email';
import errorMessages from '../../lib/errorMessages';
import { customAjv } from '../../lib/customAjv';

export const FormReclamation = ({
	onChange, onEdit, onSubmit, formData, secondTabOpen,
	onChangeSpecific,
}) => {
	const getDemandeSchemaMemo = memoizeOne(getDemandeSchema);

	const handleThemeChange = (valueLabel) => {
		if (!valueLabel) {
			onChangeSpecific({
				theme: '',
				demandeType: '',
			});
		} else {
			const { value } = valueLabel;
			const theme = themes[value];
			onChangeSpecific({
				theme: value,
				demandeType: theme.demandeTypes.length > 1 ? '' : theme.demandeTypes[0] || '',
			});
		}
		onEdit(false);
	};

	const handleVousEtesChange = ({ value }) => {
		onChangeSpecific({
			vousEtes: value,
			theme: '',
			demandeType: '',
		});
		onEdit(false);
	};

	const getschema = () => {
		if (themes[formData.theme] && themes[formData.theme].faq === true) {
			return {};
		}
		return getDemandeSchemaMemo(formData.vousEtes, formData.theme, formData.demandeType);
	};

	const renderMotifs = useCallback((theme) => {
		let FaqTheme;
		switch (theme) {
		case 'rdv':
			FaqTheme = (<Rdv />);
			break;
		case 'faqAssuranceAttestation':
			FaqTheme = (<FormFaqAssuranceAttestation />);
			break;
		case 'faqAssuranceConsulterModifier':
			FaqTheme = (<FormFaqAssuranceConsulterModifier />);
			break;
		case 'faqAssuranceDeclarerSinistre':
			FaqTheme = (<FormFaqAssuranceDeclarerSinistre />);
			break;
		case 'faqCodeAccesInternet':
			FaqTheme = (<FormFaqCode />);
			break;
		case 'faqFaq':
			FaqTheme = (<FormFaqFaq />);
			break;
		case 'faqModificationAdresse':
			FaqTheme = (<FormFaqModificationAdresse />);
			break;
		case 'faqModificationEmail':
			FaqTheme = (<FormFaqModificationEmail />);
			break;
		// case 'faqRenouvellementChequier':
		// 	FaqTheme = (<FormFaqCheckbook />);
		// 	break;
		case 'faqOptionInternationale':
			FaqTheme = (<FormFaqOptionInternationale />);
			break;
		case 'faqOppositionCarte':
			FaqTheme = (<FormFaqOppositionCarte />);
			break;
		case 'faqOppositionPrelevement':
			FaqTheme = (<FormFaqOppositionPrelevement />);
			break;
		case 'faqRibSmartphone':
			FaqTheme = (<FormFaqRIBSmartphone />);
			break;
		case 'faqVerrouillageCB':
			FaqTheme = (<FormFaqVerouillageTemporaire />);
			break;
		case 'faqVisualiserCode':
			FaqTheme = (<FormFaqVisualiserCodeSecret />);
			break;
		default:
			return (
				<MotifFields
					formData={formData}
					onChange={onChangeSpecific}
					onEdit={onEdit}
				/>
			);
		}
		return FaqTheme;
	}, [formData, onChangeSpecific, onEdit]);

	const filteredThemes = formData.vousEtes
		? clientTypes[formData.vousEtes].themes
		: Object.keys(themes);

	return (
		<section className="demand-form content my-4">
			<FormTitle num={1} title="JE SAISIS MA DEMANDE" />
			<Form
				ajv={customAjv}
				schema={getschema()}
				errorMessages={errorMessages}
				data={formData}
				onSubmit={onSubmit}
				onChange={onChange}
			>
				<FormGroup className="row">
					<Label className="strongs col-md-3 col-lg-4" htmlFor="reclamation-vousEtes">Vous êtes* :</Label>
					<Col md="7" lg="6">
						<Field
							boldStyle
							component={SimpleSelect}
							id="reclamation-vousEtes"
							name="vousEtes"
							onChange={handleVousEtesChange}
							options={
								Object.keys(clientTypes).map((ct) => ({
									value: ct,
									label: clientTypes[ct].label,
								}))
							}
							placeholder="Vous êtes"
							value={formData.vousEtes}
						/>
						<FieldError name="vousEtes" />
					</Col>
				</FormGroup>
				<FormGroup className="row">
					<Label className="strongs col-md-3 col-lg-4" htmlFor="reclamation-theme">Thème* :</Label>
					<Col md="7" lg="6">
						<Field
							component={SelectTheme}
							disabled={!formData.vousEtes}
							id="reclamation-theme"
							name="theme"
							onChange={handleThemeChange}
							options={filteredThemes}
							type="select"
							value={formData.theme}
						/>
						<FieldError name="theme" />
					</Col>
				</FormGroup>
				{
					renderMotifs(formData.theme)
				}
				{ !secondTabOpen
				&& (
					themes[formData.theme] && themes[formData.theme].faq !== true
				)
					? (
						<Row>
							<Col md={{ size: 7, offset: 3 }} lg={{ size: 6, offset: 4 }} className="text-left">
								<Button
									type="submit"
									name="submit-button"
									size="xl"
									color="danger"
								>
									VALIDER
								</Button>
							</Col>
						</Row>
					)
					: null }
			</Form>
		</section>
	);
};

FormReclamation.propTypes = {
	formData: PropTypes.shape({
		demandeType: PropTypes.string,
		files: PropTypes.arrayOf(PropTypes.shape({})),
		isClient: PropTypes.bool.isRequired,
		theme: PropTypes.string,
		titulaire: PropTypes.bool,
		vousEtes: PropTypes.string,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	onChangeSpecific: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	secondTabOpen: PropTypes.bool.isRequired,
};
